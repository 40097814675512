<template>
    <div class="page1">
        <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>
        <!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
        <ETable
                :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <el-button type="text" @click="editDialog(scope.row)">修改</el-button>
                        <el-button type="text " @click="deleteRow(scope.row.id)">删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
                 width="40%"
                 @handleClick="saveData"
                 :disabled="saveDisabled"
        >
            <EForm
                    :formColumns="dialogFormColumns"
                    :rowSize="1"
                    :optionsBtn="false"
                    :actionBtn="true"
                    :formData="dialogForm"
                    ref="form"
                    :searchFlag="false"
                    :labelPosition="labelPosition"
                    :labelWidth="labelWidth"
                    :formRules="formRules"
            >
            </EForm>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import {identity_type, status} from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import {otherMixin} from '@/components/mixin';
  import {mapGetters} from 'vuex'

  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '门',
            prop: 'doorName',
          },
          {
            label: '车道',
            prop: 'laneName',
          },
        ],
        dialogVisible: false,
        tableData: [],
        count: null,
        exportData: {},
        title: '增加',
        dialogFormColumns: [
          // {
          //   title: '交易区：',
          //   type: 'text',
          //   property: 'tradeSector',
          //   placeHolder: '最多可录入20字',
          //   show: true,
          // },
          {
            title: '门：',
            type: 'text',
            property: 'doorName',
            placeHolder: '最多可录入20字',
            show: true,
          },
          // {
          //   title: '车场编号：',
          //   type: 'text',
          //   property: 'carPartNo',
          //   placeHolder: '最多可录入20字',
          //   show: true,
          // },
          // {
          //   title: '类型：',
          //   type: 'text',
          //   property: 'type',
          //   placeHolder: '最多可录入20字',
          //   show: true,
          // },
          {
            title: '通道：',
            type: 'text',
            property: 'laneName',
            placeHolder: '最多可录入20字',
            show: true,
          },
          // {
          //   title: '车场编号：',
          //   type: 'text',
          //   property: 'tradeSector',
          //   placeHolder: '最多可录入20字',
          //   show: true,
          // },
          // {
          //   title: '厂家类型：',
          //   type: 'text',
          //   property: 'manufactorType',
          //   placeHolder: '最多可录入20字',
          //   show: true,
          // },
        ],
        labelPosition: 'left',
        labelWidth: '100px',
        formRules: {
          doorName: vxRule(true, '', "blur", "门不能为空"),
          laneName: vxRule(true, '', "blur", "通道不能为空"),
        },
        dialogForm: {
          // carPartNo: '',
          doorName: '',
          // type: '',
          // manufactorType: '',
          laneName: '',
          // tradeSector: ''
        },
        searchForm: {
          offset: 1,
          limit: 10
        }
      };
    },
    watch: {},
    created() {
      this.getData();

    },
    components: {ETable, EDialog, EButton, EForm},
    computed: {
      ...mapGetters(['buttonList']),
    },
    beforeMount() {
    },
    methods: {
      deleteRow(id){
        this.$messageBox
            .confirm(`确定删除该数据吗？`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then((res) => {
              this.laneCommandRemove(id);
            })
            .catch((res) => {});
      },
      async laneCommandRemove(id){
        let res=await Http.laneCommandRemove({id})
        if(res.code==200){
          this.$message.success(res.msg)
          this.getData()

        }
      },
      editDialog(data) {
        this.title='修改'
        this.dialogForm = {
          id: data.id,
          // carPartNo: data.carPartNo,
          doorName: data.doorName,
          // type: data.type,
          // manufactorType: data.manufactorType,
          laneName: data.laneName,
          // tradeSector: data.tradeSector,
        }
        this.dialogVisible=true
      },
      saveData() {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          console.log(object);
          if (valid) {
            this.laneCommandSave();
          } else {
            return false;
          }
        });
      },
      async laneCommandSave() {
        let res = await Http.laneCommandSave(this.dialogForm)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.cancelDialog()
          this.getData()
        }
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm={
          id: '',
          carPartNo: '',
          doorName: '',
          type: '',
          manufactorType: '',
          laneName: '',
          tradeSector: '',
        }
        this.title='增加'
      },
      onSearch() {
        this.searchForm.offset = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.offset = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.limit = size;
        this.getData();
      },

      async getData() {
        let res = await Http.laneCommandList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.rows;
          this.count = res.data.total;
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
